/*
*   @site           Lizzo: Ring Game
*   @function       Scene: Game
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/

//-----------------------------------------------
// Imports
//-----------------------------------------------

//Game Objects
import PuckManager from '@/objects/game/puckManager';
import RingManager from '@/objects/game/ringManager';
import ScorePanel from '@/objects/game/scorePanel';


//Utilities
//import Helper from '@/utilities/helper';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Game extends Phaser.Scene {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor() {
    //Main
    super({key: 'Game'});
  }


  //-----------------------------------------------
  // Create
  //-----------------------------------------------
  create(/* data */) {
    //Music
    /*Sounds.music('music-intro', { loop: true, volume: Helper.constants.VOLUME_MUSIC}, true);

    //State Manager (starts the game up)*/
    //console.log(Helper.deployMode(), window.state.getState());

    //Add all of our UI elements
    this.initUI();

    //Pucks
    this.pucks = new PuckManager(this);
    this.rings =  new RingManager(this);
    this.scores = new ScorePanel(this);

    //Game already started (if it took ages to load!)
    if(window.state.getState() === 'game-on') {
      PubSub.publish('game', {method:'start'});
    }

    //Listen for state events
    PubSub.subscribe('state', (id, data) => { this.stateEvent(data); });

    //ACtivate the sound menu
    $('.navigation').addClass('active');
  }


  //-----------------------------------------------
  // State Event
  //-----------------------------------------------
  stateEvent(data) {
    if(data.type === 'set') {
      if(data.id === 'game-on') {
        PubSub.publish('game', {method:'start'});
      } else if(data.id === 'game-over') {
        PubSub.publish('game', {method:'end'});
      }
    }
  }

  //-----------------------------------------------
  // UI: Environment / Scenery etc.
  //-----------------------------------------------
  initUI() {
    //Logo
    this.logo = this.add.sprite(20, 20, 'game-assets', 'logo.png').setOrigin(0, 0);
    this.logo.depth = 200;

    //Cover
    let cover = this.add.graphics(0, 0);
    cover.fillStyle(0xcb0674, 1);
    cover.fillRect(0, 0, 1080, 1920);
    cover.depth = 1000;

    //Animate out
    this.tweens.add({ targets: cover, x:1080, duration: 300, ease: 'Power2', repeat: 0, delay: 0});
  }


  //-----------------------------------------------
  // Update
  //-----------------------------------------------
  update() {
    let state = window.state.getState();
    this.pucks.update();
    this.rings.update();
    this.scores.update(state);
  }
}
