/*
*   @site           Lizzo: Ring Game
*   @function       Object: Puck Manager
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utilities
import Helper from '@/utilities/helper';
import Sounds from '@/utilities/sounds';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class PuckManager {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene) {
    //Add
    this.scene = scene;

    //Vars
    this.layout = {
      puck:{upY:-14, downY:48, radius:274/2, offsetX:25, offsetY:25},
      grid:{initX:30, initY:445, spaceX:350, spaceY:360}
    };

    //Config Bits (speeds etc.) - reset this
    
    //Lists
    this.items = [];
   
    //Pubsubs
    this.addPubSubs();

    //Create the pucks
    this.createPucks();
  }


  //-----------------------------------------------
  // Event listeners
  //-----------------------------------------------
  addPubSubs() {
    //State / Game events
    PubSub.subscribe('game', (id, data) => { this.gameEvent(data); });
  }


  //-----------------------------------------------
  // Create the pucks
  //-----------------------------------------------
  createPucks() {
    //Position
    let i, j, top, bottom, puck, doorLeft, doorRight, doorShadow;
    let x = this.layout.grid.initX, y = this.layout.grid.initY;

    //Vars
    let baseDepth = 0;

    //Loop
    for(i = 0; i < 3; i++) {
      for(j = 0; j < 3; j ++) {

        //Puck
        puck = this.scene.add.sprite(x + this.layout.puck.offsetX, y + this.layout.puck.offsetY + this.layout.puck.downY, 'game-assets', 'puck.png').setOrigin(0, 0);
        puck.depth = baseDepth; //basically behind everything on the screen until it pops up

        //Doors (all need to be beneath 50)
        doorLeft = this.scene.add.sprite(x + 12, y + this.layout.puck.downY + 10, 'game-assets', 'door-left.png').setOrigin(0, 0);
        doorRight = this.scene.add.sprite(doorLeft.x + doorLeft.width, doorLeft.y, 'game-assets', 'door-right.png').setOrigin(0, 0);
        doorShadow = this.scene.add.sprite(x + this.layout.puck.offsetX, y + this.layout.puck.offsetY + this.layout.puck.downY - 2, 'game-assets', 'door-shadow.png').setOrigin(0, 0).setAlpha(0.5);
        doorLeft.depth = doorRight.depth = doorShadow.depth = baseDepth + 1; //behind the door

        //Top (over the holes until the puck comes up)
        top = this.scene.add.sprite(x, y, 'game-assets', 'hole-top.png').setOrigin(0, 0);
        top.depth = baseDepth + 2;

        //Bottom half of the hole (covers pretty much everything)
        bottom = this.scene.add.sprite(x, y + 184, 'game-assets', 'hole-bottom.png').setOrigin(0, 0);
        bottom.depth = baseDepth + 4;

        //Fills
        let height = this.layout.grid.spaceY - 324 + 30;
        this.addFill(x, y + 324, 324, height, baseDepth + 4); //stop puck poking out of the bottom

        //Add to the register
        this.items.push(
          {
            x:x + 162, //centre point
            y:y + 162, //centre point
            puck:puck,
            doorLeft:doorLeft,
            doorRight:doorRight,
            doorShadow:doorShadow,
            state:'down',
            lizzo:true
          }
        );

        //Inc column
        x += this.layout.grid.spaceX;
        baseDepth += 10;
      }

      //New row
      y += this.layout.grid.spaceY;
      x = this.layout.grid.initX;
    }

    //Test
    this.popItem();
  }


  //-----------------------------------------------
  // Pop Item
  //-----------------------------------------------
  addFill(x, y, width, height, depth = 1, colour = 0x89d0e8) {
    let fill = this.scene.add.graphics();
    fill.fillStyle(colour);
    fill.fillRect(x, y, width, height);
    fill.depth = depth;
  }


  //-----------------------------------------------
  // Pop Item
  //-----------------------------------------------
  popItem() {
    let id = Math.floor(Math.random() * 8);
    this.scene.time.delayedCall(750 + (Math.random() * 1000), this.itemUp, [id], this);
  }


  //-----------------------------------------------
  // Item Up
  //-----------------------------------------------
  itemUp(id) {
    //Act
    if(this.items[id].state === 'down') {
      let item = this.items[id];
      let speed = {doors:300, puck:500, kill:Helper.constants.GAME_CONFIG.puckUpTime};

      //Open doors
      this.scene.tweens.add({ targets: item.doorLeft, scaleX:0, duration: speed.doors, ease: 'Power2', repeat: 0, delay: 0});
      this.scene.tweens.add({ targets: item.doorRight, x: '+=140', scaleX:0, duration: speed.doors, ease: 'Power2', repeat: 0, delay: 0});
      this.scene.tweens.add({ targets: item.doorShadow, alpha: 0, duration: speed.doors, ease: 'Power2', repeat: 0, delay: 0});

      //Popup the disc up (depth swap)
      this.scene.tweens.add({ targets: item.puck, y: '-=' + (this.layout.puck.downY - this.layout.puck.upY), duration: speed.puck, ease: 'Expo', repeat: 0, delay: speed.doors * 0.65});
      this.scene.time.delayedCall(speed.doors * 0.65, this.depthSwap, [id, 'up'], this);

      //State
      this.items[id].state = 'up';

      //Kill it after some time
      this.scene.time.delayedCall(speed.kill, this.itemDown, [id], this);

      //Open
      if(window.state.getState() === 'game-on') {
        Sounds.play('sfx-slot-open', {volume:0.45, delay:0.25, rate:1.3});
      }
    }

    //Go again
    this.popItem();
  }


  //-----------------------------------------------
  // Item Down
  //-----------------------------------------------
  itemDown(id) {
    //Act
    if(this.items[id].state === 'up') {
      let item = this.items[id];
      let speed = {doors:300, puck:300, kill:600};

      //Disc back down
      this.scene.tweens.add({ targets: item.puck, y: '+=' + (this.layout.puck.downY - this.layout.puck.upY), duration: speed.puck, ease: 'Expo', repeat: 0, delay: 0});
      this.scene.time.delayedCall(speed.puck, this.depthSwap, [id, 'down'], this);

      //Close doors
      this.scene.tweens.add({ targets: item.doorLeft, scaleX:1, duration: speed.doors, ease: 'Power2', repeat: 0, delay: speed.puck});
      this.scene.tweens.add({ targets: item.doorRight, x: '-=140', scaleX:1, duration: speed.doors, ease: 'Power2', repeat: 0, delay: speed.puck});
      this.scene.tweens.add({ targets: item.doorShadow, alpha: 0.5, duration: speed.doors, ease: 'Power2', repeat: 0, delay: speed.puck}); 

      //State after time
      this.scene.time.delayedCall(speed.kill, function(id) { this.items[id].state = 'down'; }.bind(this), [id], this);

      //Open
      if(window.state.getState() === 'game-on') {
        Sounds.play('sfx-slot-open', {volume:0.35, delay:0, rate:1.5});
      }
    }
  }


  //-----------------------------------------------
  // Check if we scored
  //-----------------------------------------------
  checkScore(x, y) {
    let diffX, diffY, distance;
    let closest = {distance:0, id:-1};
    for(let i = 0; i < this.items.length; i++) {
      if(this.items[i].state === 'up') {
        diffX = this.items[i].x - x;
        diffY = this.items[i].y - y;
        distance = Math.sqrt(Math.pow(diffX, 2) + Math.pow(diffY, 2));
        if(distance <= Helper.constants.GAME_CONFIG.scoreDistance && (closest.id === - 1 || distance < closest.distance)) {
          closest.distance = distance;
          closest.id = i;
        }
      }
    }

    //Anything?
    if(closest.id !== -1) {
      
      let score = Math.ceil((1 - (closest.distance / Helper.constants.GAME_CONFIG.scoreDistance)) * Helper.constants.GAME_CONFIG.scoreMax);
      score = Math.ceil(score / 5) * 5;

      //Fire an event!
      PubSub.publish('game', {method:'score', value:score, ring:{x:x, y:y}, puck:{x:this.items[closest.id].x, y:this.items[closest.id].y}});

    }
  }


  //-----------------------------------------------
  // Depth Swap
  //-----------------------------------------------
  depthSwap(id, action) {
    let depth = (action === 'up') ? (id * 10) + 4 : id * 10;
    this.items[id].puck.depth = depth;
  }


  //-----------------------------------------------
  // Game Events
  //-----------------------------------------------
  gameEvent(data) {
    if(data.method === 'start') {
      this.gameStart();
    } else if(data.method === 'end') {
      this.gameEnd();
    } else if(data.method === 'ring-land') {
      //Check the ring
      this.checkScore(data.x, data.y);
    }
  }


  //-----------------------------------------------
  // Start / End
  //-----------------------------------------------
  gameStart() {}
  gameEnd() {}



  //-----------------------------------------------
  // Update
  //-----------------------------------------------
  update() {
   
  }
}
