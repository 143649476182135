/*
*   @site           Lizzo: Ring Game
*   @function       Utility Object: Resize Manager
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/

//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utils
//import Sounds from '@/utilities/sounds';
//import Helper from '@/utilities/helper';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class ResizeManager {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor() {
    //Event listener
    $(window).on('resize orientationchange', function() {
      this.resizeDetected();
    }.bind(this));

    //Elements
    this.gameMaxScale = 0.5;
    this.gameMargin = {x:20, y:40};
    this.gameSize = {w:1080 * this.gameMaxScale, h:1920 * this.gameMaxScale};
    this.elements = {
      'game':$('.game-holder'),
      'playlist':$('.playlist'),
      'navigation':$('.navigation')
    };

    //Listen
    this.resizeDo();
  }


  //-----------------------------------------------
  // State Event
  //-----------------------------------------------
  resizeDetected() {
    if(this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }
    this.resizeTimer = setTimeout(function() { this.resizeDo(); }.bind(this), 200);
  }


  //-----------------------------------------------
  // State Event
  //-----------------------------------------------
  resizeDo() {
    //Size
    let width = $(window).innerWidth();
    let height = $(window).innerHeight();

    //Assuming we are resizing to height
    let gameHeight = Math.min(this.gameSize.h, (height - 20) - (this.gameMargin.y * 2));
    let gameWidth = gameHeight * (this.gameSize.w / this.gameSize.h);

    //If this gives us a bad width, then we switch to width instead
    if(gameWidth > (width - 20) - (this.gameMargin.x * 2)) {
      gameWidth = Math.min(this.gameSize.w, (width - 20) - (this.gameMargin.x * 2));
      gameHeight = gameWidth * (this.gameSize.h / this.gameSize.w);
    }

    //Change the size
    this.elements.game.css('width', Math.floor(gameWidth));
    this.elements.game.css('height', Math.floor(gameHeight));
    this.elements.playlist.css('width', Math.floor(gameWidth));
    this.elements.playlist.css('height', Math.floor(gameHeight));

    this.elements.navigation.css('right', Math.floor((width - gameWidth) / 2) + 10);
    this.elements.navigation.css('bottom', Math.floor((height - gameHeight) / 2) - 25);


  }


}
