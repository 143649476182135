/*
*   @site           Lizzo: Ring Game
*   @function       State Manager
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/

//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utils
import Sounds from '@/utilities/sounds';
//import Helper from '@/utilities/helper';



//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class StateManager {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene, initState = 'intro') {
    //Current
    this.scene = scene;
    this.current = '';

    //States for the game
    //  intro                 -   Intro Page
    //  form                  -   Register
    //  game-on               -   Game On
    //  game-over             -   Game Over

    //Go
    this.setState(initState);

    //Listen for state change requests (from menu, or automated game functions etc.)
    PubSub.subscribe('state', (id, data) => { this.stateEvent(data); });
  }


  //-----------------------------------------------
  // State Event
  //-----------------------------------------------
  stateEvent(data) {
    if(data.type === 'change') {
      this.setState(data.id);
    }
  }


  //-----------------------------------------------
  // Set the state
  //-----------------------------------------------
  setState(state) {
    if(state !== this.current) {
      //Store the new state
      this.current = state;  

      //Do certain things based on the state we are going to etc.
      if(state === 'game-over') {
        Sounds.play('sfx-ring', {volume:0.8});
      }

      //Fire 'state_set' event
      PubSub.publish('state', {type:'set', id:state});
    }
  }


  //-----------------------------------------------
  // Get the state
  //-----------------------------------------------
  getState() {
    return this.current;
  }
}
