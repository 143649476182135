/*
*   @site           Lizzo: Ring Game
*   @function       Object: Ring Manager
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utilities
import Helper from '@/utilities/helper';
import Sounds from '@/utilities/sounds';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class RingManager {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene) {
    //Add
    this.scene = scene;

    //Throwing config
    this.config = Helper.constants.GAME_CONFIG.rings;

    //Particles
    this.particles = this.scene.add.particles('game-assets', 'particle.png');
    this.particles.depth = 999;

    //Emitter
    this.emitter = this.particles.createEmitter({
      angle: { min: 140, max: 400 },
      speed: {min:500, max:800},
      rotate: {min:0, max:360},
      gravityY:1000,
      lifespan: 800,
      quantity: {min:10, max:14},
      scale: { start: 0.8, end: 0 },
      on:false
    });

    //Pointer
    this.pointerInfo = {id:-1, moved:false};

    //Text
    this.createScoreTextPool();
    
    //Pubsubs
    this.addPubSubs();

    //Shoot area
    this.background = this.scene.add.sprite(540, 1920, 'game-assets', 'shoot-area.png').setOrigin(0.5, 1);
    this.background.depth = 100;

    //Rings!
    this.ringCurrent = -1;
    this.rings = [];
    let ring, shadow;
    for(let i = 0; i < 10; i++) {
      ring = this.scene.add.sprite(540, 2100, 'game-assets', 'ring.png'); //2100
      shadow = this.scene.add.sprite(ring.x, ring.y, 'game-assets', 'ring-shadow.png').setAlpha(0.8);
      ring.depth = 102;
      shadow.depth = 101;

      //Add
      this.rings.push({
        ring:ring,
        shadow:shadow,
        shadowOffset:10,
        status:'dead'
      });
    }

    //Get the next ring
    this.getNextRing();

    //Input
    this.scene.input.on('pointermove', function (pointer) { this.touchMove(pointer); }.bind(this));
    this.scene.input.on('pointerup', function(pointer) { this.touchEnd(pointer); }.bind(this));
    this.scene.input.on('pointerdown', function(pointer) { this.touchStart(pointer); }.bind(this));
  }


  //-----------------------------------------------
  //Text pool for points
  //-----------------------------------------------
  createScoreTextPool() {
    this.pts = [];
    let text;
    for(let i = 0; i < 5; i ++) {
      text =  this.scene.add.dynamicBitmapText(10, 10 + 30 * i, 'HelveticaNeueBold-Outline', '', 36).setVisible(false).setOrigin(0.5, 0.5);
      text.letterSpacing = 2;
      text.depth = 999;
      this.pts.push({text:text, active:false});
    }
  }


  //-----------------------------------------------
  // Score text display
  //-----------------------------------------------
  scoreTextDisplay(x, y, value) {
    for(let i = 0; i < this.pts.length; i++) {
      if(!this.pts[i].active) {
        //Show it
        this.pts[i].text.text = value + 'PTS';
        this.pts[i].text.setVisible(true).setScale(0, 0).setPosition(x, y).setAlpha(1);

        //Scale in, fade up
        this.scene.tweens.add({ targets: this.pts[i].text, scaleX:1, scaleY:1, duration: 200, ease: 'Elastic.InOut', repeat: 0, delay: 0});
        this.scene.tweens.add({ targets: this.pts[i].text, y:y - 50, alpha:0, duration: 1000, ease: 'Circular.InOut', repeat: 0, delay: 100});

        //Active
        this.pts[i].active = true;

        //Timed reset
        this.scene.time.delayedCall(1200, this.scoreTextReset, [i], this);
        break;
      }
    }
  }


  //-----------------------------------------------
  // Score text reset
  //-----------------------------------------------
  scoreTextReset(id) {
    this.pts[id].active = false;
  }


  //-----------------------------------------------
  // Touch Up
  //-----------------------------------------------
  touchEnd(pointer) {
    if(pointer.id === this.pointerInfo.id && window.state.getState() === 'game-on' && this.ringCurrent !== -1) {
      if(this.pointerInfo.moved) {
        let diffX = pointer.x - this.pointerInfo.x;
        let diffY = pointer.y - this.pointerInfo.y;
        let distance = Math.sqrt(Math.pow(diffX, 2) + Math.pow(diffY, 2));
        let angle = (180 / Math.PI) * Math.atan2(diffY, diffX);
        let time = this.scene.game.getTime() - this.pointerInfo.time;
        let speed = distance / time;

        //Moved enough to count as a swipe
        if(speed > this.config.throwThreshold && angle >= -180 && angle < 0) {
          //Valid swipe, so throw the ring!
          this.throwCurrent(diffX, diffY, speed);
        } else {
          //Drop the ring back down
          this.ringLift(false);
        }
      } else {
        //Released without moving
        this.ringLift(false);
      }
    }
  }

  //-----------------------------------------------
  // Touch moved (this is when we start tracking)
  //-----------------------------------------------
  touchMove(pointer) {
    if(pointer.id === this.pointerInfo.id && window.state.getState() === 'game-on' && this.ringCurrent !== -1 && !this.pointerInfo.moved) {
      this.pointerInfo.x = pointer.x;
      this.pointerInfo.y = pointer.y;
      this.pointerInfo.moved = true;
      this.pointerInfo.time = this.scene.game.getTime();
    }
  }


  //-----------------------------------------------
  // Touch Down
  //-----------------------------------------------
  touchStart(pointer) {
    if(window.state.getState() === 'game-on' && this.ringCurrent !== -1) {
      //Store the pointer details
      this.pointerInfo.id = pointer.id;
      this.pointerInfo.x = pointer.x;
      this.pointerInfo.y = pointer.y;
      this.pointerInfo.moved = false;

      //Lift the ring up
      this.ringLift(true);
    }
  }


  //-----------------------------------------------
  // Ring Lift/Drop
  //-----------------------------------------------
  ringLift(up = true, speed = 300, ease = 'Power2') {
    //Move
    this.scene.tweens.add({ targets: this.rings[this.ringCurrent], shadowOffset:(up) ? 50 : 10, duration: speed, ease: ease, repeat: 0, delay: 0});
    this.scene.tweens.add({ targets: this.rings[this.ringCurrent].ring, scaleX:(up) ? 1.3 : 1, scaleY:(up) ? 1.3 : 1, duration: speed, ease: ease, repeat: 0, delay: 0});
  
    if(!up) {
      //Reset everything
      this.pointerInfo.id = -1;
      this.pointerInfo.moved = false;
    }
  }


  //-----------------------------------------------
  // Throw the current ring
  //-----------------------------------------------
  throwCurrent(velocityX, velocityY, speed) {
    //Throw the current ring
    let id = this.ringCurrent;

    //Swoosh
    Sounds.play('sfx-swoosh', {volume:0.5});
    
    //Enable the body
    this.scene.physics.world.enable([this.rings[id].ring]);

    //Size, bounce and collide bounds
    this.rings[id].ring.body
      .setCircle(142)
      .setBounce(1, 1)
      .setCollideWorldBounds(true);
    

    //Status
    this.rings[id].status = 'moving';

    //Go!
    this.rings[id].ring.body
      .setVelocity(velocityX * this.config.throwMultiplier * speed, velocityY * this.config.throwMultiplier * speed);

    //Drop the ring back down with a bounce
    this.ringLift(false, 500, 'Bounce');

    //Reset ring ID, then get another one after a delay
    this.ringCurrent = -1;
    this.scene.time.delayedCall(Helper.constants.GAME_CONFIG.ringDelay, this.getNextRing, [], this);
  }

  //-----------------------------------------------
  // Get the next 'dead' ring
  //-----------------------------------------------
  getNextRing() {
    if(this.ringCurrent === -1) {
      for(let i = 0; i < this.rings.length; i++) {
        if(this.rings[i].status === 'dead') {
          this.ringIn(i);
          break;
        }
      }
    }
  }


  //-----------------------------------------------
  // Ring in
  //-----------------------------------------------
  ringIn(id) {
    //Move it in
    this.scene.tweens.add({ targets: this.rings[id].ring, y:1720, duration: 300, ease: 'Power2', repeat: 0, delay: 0});
    this.scene.tweens.add({ targets: this.rings[id].shadow, y:1720, duration: 300, ease: 'Power2', repeat: 0, delay: 0});

    //Flag
    this.ringCurrent = id;
    this.rings[id].status = 'ready';
  }


  //-----------------------------------------------
  // Event listeners
  //-----------------------------------------------
  addPubSubs() {
    //State / Game events
    PubSub.subscribe('game', (id, data) => { this.gameEvent(data); });
  }



  //-----------------------------------------------
  // Game Events
  //-----------------------------------------------
  gameEvent(data) {
    if(data.method === 'start') {
      this.gameStart();
    } else if(data.method === 'end') {
      this.gameEnd();
    } else if(data.method === 'score') {
      //Sparkles!
      Sounds.play('sfx-sparkle', {volume:0.8});
      this.particles.emitParticleAt(data.ring.x,  data.ring.y);
      this.scoreTextDisplay(data.ring.x,  data.ring.y, data.value);
    }
  }


  //-----------------------------------------------
  // Game Start
  //-----------------------------------------------
  gameStart() {}


  //-----------------------------------------------
  // Game End
  //-----------------------------------------------
  gameEnd() {
    //Put the ring back down
    if(this.ringCurrent !== -1 && this.rings[this.ringCurrent].status === 'ready') {
      this.ringLift(false);
    }
  }



  //-----------------------------------------------
  // Update
  //-----------------------------------------------
  update() {
    //Update any ring shadows for active rings
    this.updateRings();
  }


  //-----------------------------------------------
  // Update active rings
  //-----------------------------------------------
  updateRings() {
    for(let i = 0; i < this.rings.length; i++) {
      if(this.rings[i].status !== 'dead') {
        //Shadow
        this.rings[i].shadow.x = this.rings[i].ring.x;
        this.rings[i].shadow.y = this.rings[i].ring.y + this.rings[i].shadowOffset;

        //Decelerate all moving rings
        if(this.rings[i].ring.body && this.rings[i].status === 'moving') {
          //Decelerate
          this.rings[i].ring.body.velocity.x *= this.config.decelerationRate;
          this.rings[i].ring.body.velocity.y *= this.config.decelerationRate;

          //Ended
          if(Math.abs(this.rings[i].ring.body.velocity.x) < this.config.decelerationThreshold && Math.abs(this.rings[i].ring.body.velocity.y) < this.config.decelerationThreshold) {
            //Kill the ring
            this.resetRing(i);
          }
        }
      }
    }
  }

  //-----------------------------------------------
  // Reset a ring after velocity gets too low etc.
  //-----------------------------------------------
  resetRing(id) {
    //Halt
    this.rings[id].ring.body.setVelocity(0, 0);
    this.scene.physics.world.disableBody(this.rings[id].ring.body); //remove from simulation

    //Fire an event
    if(window.state.getState() === 'game-on') {
      PubSub.publish('game', {method:'ring-land', x:this.rings[id].ring.x, y:this.rings[id].ring.y});
    }

    //Mark as dead
    this.rings[id].status = 'dead';

    //Reset position (might need to do this after destroy has happened)
    this.rings[id].ring.x = this.rings[id].shadow.x = 540;
    this.rings[id].ring.y = this.rings[id].shadow.y = 2100;

    //New one?
    this.getNextRing();


  }

}
