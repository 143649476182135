/*
*   @site           Lizzo: Ring Game
*   @function       Utility Object: Modals
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/



//-----------------------------------------------
// Import
//-----------------------------------------------
import Helper from '@/utilities/helper';

import html2canvas from 'html2canvas';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Modal {
  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(wrapper, cookie, crm) { //, crm, timer
    //Defaults
    this.wrapper = wrapper;
    this.cookie = cookie;
    this.crm = crm;
    //this.timer = timer;

    //Debug saved user
    //console.log(this.cookie.user);

    //State
    this.state = {
      current:''
    };

    //Binds
    this.bindButtons();
    this.bindTopsifyButton();
    this.bindForms();

    //User state
    this.checkUserState();

    //CRM
    PubSub.subscribe('state', (id, data) => { this.stateEvent(data); });
    PubSub.subscribe('crm', (id, data) => { this.crmEvent(data); });
  }


  //-----------------------------------------------
  // Check user state
  //-----------------------------------------------
  checkUserState() {
    //Classes
    this.wrapper.removeClass('registered complete');
    if(this.cookie.user.email !== '') {
      this.wrapper.addClass((this.cookie.user.score !== undefined && this.cookie.user.score !== '') ? 'complete' : 'registered');
    }

    //Name / Date / Score
    let now = new Date();
    this.wrapper.find('.name').text(this.cookie.user.name.split(' ')[0]);
    this.wrapper.find('.date-day').text(Helper.ordinalSuffix(now.getDate()));
    this.wrapper.find('.date-month').text(now.toLocaleString('default', { month: 'long' }));
    this.wrapper.find('.date-year').text(now.getFullYear());
    
    this.wrapper.find('.score').text((this.cookie.user.score !== '') ? this.cookie.user.score : 0);
    this.wrapper.find('.score-last').text(Helper.gameInfo.score);

    //Image
    this.wrapper.find('.profile-picture').css('background-image', 'url(' + this.cookie.user.image + ')');
    this.wrapper.find('.profile-picture').css('display', (this.cookie.user.image === '') ? 'none' : 'block');
  }


  //-----------------------------------------------
  // State Event
  //-----------------------------------------------
  stateEvent(data) {
    if(data.id === 'intro' || data.id === 'form' || data.id === 'game-over') {
      //Normal Modal
      this.activate(data.id);
    } else if(data.id === 'game-on') {
      //Kill any modals
      this.deactivate();
    } 
  }


  //-----------------------------------------------
  // CRM Event
  //-----------------------------------------------
  crmEvent(data) {
    if(data.id === 'subscribe' && data.response.status !== undefined) {
      if(data.response.status === 'success') {
        //SUCCESS
        //_satellite.track('email sign-up');
        this.login(data.sent.firstname, data.sent.email, '', 'crm');
      } else if(data.response.status === 'error') {
        //ERROR
        console.log(data);

        //Email error?
        if(data.response.message !== undefined && data.response.message.indexOf('email') !== -1) {
          $('#email').addClass('error');
        }

        //Form
        this.activate('form');
      }
    }
  } 


  //-----------------------------------------------
  // Bind Forms
  //-----------------------------------------------
  bindForms() {
    let form = $('#form-register');
    form.on('submit', function(form, event) {
      //Send it here
      this.formSubmit(form);

      //Kill the process
      event.preventDefault();
      event.stopPropagation();
    }.bind(this, form));

    //Inputs
    form.find('input').on('focus keydown paste copy', function() {
      $(this).removeClass('error');
    });
  }




  //-----------------------------------------------
  // Form submit
  //-----------------------------------------------
  formSubmit(form) {
    let items = form.find('input');
    let item, value;
    let data = {};
    for(let i = 0; i < items.length; i++) {
      //Read the value
      item = $(items[i]);
      value = item.val();
      if(item.attr('type') === 'checkbox') {
        value = item.prop('checked');
      }

      //Clear errors
      item.removeClass('error');

      //Data
      data[item.attr('id')] = value;
    }

    //Wait screen
    this.activate('wait');

    //CRM submit
    setTimeout(function(data) {
      this.crm.register(data.name, data.email, data.zipcode, Helper.constants.CRM.country, data.follow);
    }.bind(this, data), 250);
  }


  //-----------------------------------------------
  // Bind Topsify Buttons
  //-----------------------------------------------
  bindTopsifyButton() {
    //Topsify Button
    this.connectButton = new window.WMGConnect('.wmg-button', {
      exacttarget_datasource: Helper.constants.CRM.dataSource,
      opt_in_thank_you_enabled: false,
      opt_in_dialogue_enabled: false
    });

    //Tracking
    $('.wmg-button').on('click', function() {
      //Helper.warnerTrack('Login With Spotify Click');
    }.bind(this));

    //Callback
    this.connectButton.setCallback(function(data) {
      if(data.user && data.user.email) {
        //_satellite.track('spotify email sign-up');
        this.login(data.user.name, data.user.email, data.user.image || '', 'spotify');
      }
    }.bind(this));
  }


  //-----------------------------------------------
  // Bind Buttons
  //-----------------------------------------------
  bindButtons() {
    //Main form buttons
    let list = $('button[data-action]');
    let button;
    for(let i = 0; i < list.length; i++) {
      button = $(list[i]);
      button.on('click', function(id, event) {
        this.buttonClick(id);
        event.preventDefault();
        event.stopPropagation();
      }.bind(this, button.data('action')));
    }
  }


  //-----------------------------------------------
  // Button Click
  //-----------------------------------------------
  buttonClick(id) {
    if(id.indexOf('state-') === 0) {
      //State switch
      window.state.setState(id.substr(6));

      //Warner Tracking on these events?
      //Helper.warnerTrack('Register Click');
    } else if(id === 'screenshot') {
      //Screenshot
      this.screenshot('#modal-certificate');
      console.log('Screeenshot it!');
    } else {
      console.log(id);
    }
  }


  //-----------------------------------------------
  // Screenshot
  //-----------------------------------------------
  screenshot(id) {
    //Kill the buttons
    $('#modal-certificate').find('.buttons').css('display', 'none');

    //Grab the image
    html2canvas(document.querySelector(id)).then(canvas => {
      let img = canvas.toDataURL();
      let now = new Date();
      let link = document.createElement('a');
      link.download = 'Lizzo-' + this.cookie.user.name.split(' ')[0] + '-WeddingCertificate-' + now.getTime() + '.png';
      link.href = img;
      link.click();

      //Restore buttons
      $('#modal-certificate').find('.buttons').css('display', 'block');
    });
  }


  //-----------------------------------------------
  // Login
  //-----------------------------------------------
  login(name, email, image, type) {
    //Save the cookie
    this.cookie.saveUser({name:name, email:email, image:image, type:type});

    //State switch 
    window.state.setState('game-on');
  }


  //-----------------------------------------------
  // Activate
  //-----------------------------------------------
  activate(id) {
    //User state
    this.checkUserState();

    //Body
    $('body').addClass('modal-active').removeClass('modal-closing');

    //Calculate which modal we are showing...
    if(id == 'game-over') {
      //Certificate Version
      $('#modal-main').css('display', 'none');
      $('#modal-certificate').css('display', 'block');

    } else {
      //Normal
      $('#modal-main').css('display', 'block');
      $('#modal-certificate').css('display', 'none');
    }
   

    //Remove any old content
    this.killTimer();
    $('#modal-content-' + this.state.current).removeClass('active');

    //New on
    $('#modal-content-' + id).addClass('active');
    $('#modal-content-' + id).scrollTop(0);
    this.state.current = id;

    //Test
    if(id === 'form' && Helper.constants.DEPLOY_MODE === 'local') {
      $('#name').val('David Test');
      $('#email').val('bb@bb.b');
      $('#zipcode').val('12345');
    }

    //Test
    /*
    if(id === 'game-over') {
      //Hide some bits and move it to the top left corner
      $('#modal-content-game-over .buttons').css('display', 'none');
      $('#modal-main').css('transform', 'translate(0%, 0%)'); //translate(-50%, -50%)
      $('#modal-main').css('left', '0');
      $('#modal-main').css('top', '0');

      //Make the image
      domtoimage.toPng(document.getElementById('modal-main'), {quality:1})
        .then(function (dataUrl) {
            //Get the image
            var link = document.createElement('a');
            link.download = 'Lizzo-' + this.cookie.user.name.split(' ')[0] + '-WeddingCertificate.png';
            link.href = dataUrl;
            link.click();

            //Restore
            $('#modal-content-game-over .buttons').css('display', 'block');
            $('#modal-main').css('transform', 'translate(-50%, -50%)'); //translate(-50%, -50%)
            $('#modal-main').css('left', '50%');
            $('#modal-main').css('top', '50%');
        }.bind(this));
    }*/
  }


  //-----------------------------------------------
  // Deactivate
  //-----------------------------------------------
  deactivate() {
    if($('body').hasClass('modal-active')) {
      //Closing class
      $('body').addClass('modal-closing');

      //Timed kill
      this.killTimer();
      this.modalKillTimeout = setTimeout(function() {
        this.closed();
      }.bind(this), 500);
    }
  }


  //-----------------------------------------------
  // Closed
  //-----------------------------------------------
  closed() {
    $('body').removeClass('modal-active modal-closing');
    if(this.state.current !== '') {
      $('#modal-content-' + this.state.current).removeClass('active');
      this.state.current = '';
    }
  }


  //-----------------------------------------------
  // Timer
  //-----------------------------------------------
  killTimer() {
    if(this.modalKillTimeout) {
      clearTimeout(this.modalKillTimeout);
      this.modalKillTimeout = null;
    }
  }
}