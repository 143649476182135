/*
*   @site           Lizzo: Ring Game
*   @function       Utility Object: Constants
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Global
//-----------------------------------------------
export const DEPLOY_MODE = (document.location.href.indexOf('localhost:3000') !== -1) ? 'local' : 'live'; //stops debug mode
export const DEBUG_MODE = (DEPLOY_MODE === 'local') ? true : false;



//-----------------------------------------------
// Cookie
//-----------------------------------------------
export const COOKIE = {
  user:'lizRingInfo',
  consent:'lizRingConsent',
  sound:'lizRingSound'
};


export const GAME_CONFIG = {
  time:(DEPLOY_MODE === 'local') ? 30 : 30,
  puckUpTime:2500,
  scoreDistance:200,
  scoreMax:50,
  ringDelay:350, //time between each ring when throwing (stops fast throws)
  rings: {
    throwThreshold:0.5, //increase to require more vigorous swipes
    throwMultiplier:2, //increase to make movement faster
    decelerationRate:0.92, //drag on rings
    decelerationThreshold:10 //amount of speed before we halt the ring
  }
};


//-----------------------------------------------
// Dictionary
//-----------------------------------------------
export const DICTIONARY = {
  //Character Select
  'an-item-key': 'A value',
};

//-----------------------------------------------
// WARNER CRM BITS
//-----------------------------------------------
export const CRM = {
  'country':'US',
  'newsletterID':14076366,
  'ext':'5721D423-A398-4B11-8D58-1155968F2AB0:6406001',
  'dataSource':'Lizzo_FollowToPlayGame_Registration',
  'endpoints':{
    'subscribe':'https://signup.wmg.com/register',
    'unsubscribe':'https://signup.wmg.com/unsubscribe'
  }
};
