/*
*   @site           Lizzo: Ring Game
*   @function       App Index
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Import
//-----------------------------------------------

//Config
import * as config from '@/config';

//CSS
import '@/../styles/app.scss';

//Utils
import StateManager from '@/utilities/stateManager';
import ResizeManager from '@/utilities/resizeManager';
import Helper from '@/utilities/helper';
import Modal from '@/utilities/modals';
import Cookie from '@/utilities/cookie';
import CRM from '@/utilities/crm';
import Preloader from '@/utilities/preloader';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Game {
  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor() {
    //Clear pubsubs (should only be needed if repeat booted)
    PubSub.clearAllSubscriptions();

    //Debug
    $('body').addClass(Helper.constants.DEPLOY_MODE);

    //Cookie & CRM Handler
    Helper.cookie = new Cookie();
    Helper.crm = new CRM();
    Helper.resizer = new ResizeManager();

    //Modal
    Helper.modal = new Modal($('.modal-wrapper'), Helper.cookie, Helper.crm);

    //Preloader
    Helper.preloader = new Preloader();

    //Music Button
    $('#button-music').on('click', function() {
      $('.playlist').toggleClass('active');
      $('#button-music').toggleClass('active');
    }.bind(this));

    //Listen to preload events
    PubSub.subscribe('preload', (id, data) => { this.preloadEvent(data); });
  }

  //-----------------------------------------------
  // Preload Event
  //-----------------------------------------------
  preloadEvent(data) {
    if(data.method === 'images-complete') {
      this.gameStart();
    }
  }


  //-----------------------------------------------
  // Game Start
  //-----------------------------------------------
  gameStart() {
    //Reveal
    $('body').addClass('loaded').removeClass('loading');
    setTimeout(() => $('body').removeClass('loaded'), 1000);

    //State Manager
    window.state = new StateManager(this, (Helper.deployMode() === 'local') ? 'game-on' : 'intro');

    //Game (should we init this earlier?)
    window.lizzoGame = new Phaser.Game(config);
  }
}



//-----------------------------------------------
// Boot
//-----------------------------------------------
$(function() { window.mazeGame = new Game({}); });