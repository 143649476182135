/*
*   @site           Lizzo: Ring Game
*   @function       Scene: Preload
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/

//-----------------------------------------------
// Imports
//-----------------------------------------------


//Utilities
import Sounds from '@/utilities/sounds';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class Preload extends Phaser.Scene {
  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor() {
    super({
      key: 'Preload',

      //Extra files we need in advance of the main loader
      /*pack: {
        files: [{
          key: 'font-crates-image',
          type: 'image',
          url:'_fonts/Crates.png'
        }]
      }*/
    });

    //Position
    this.bar = {width:1080, height:1920, lag:5};
    this.targetX = -this.bar.width;

    //Flags
    this.flags = {
      ready: false
    };
  }


  //-----------------------------------------------
  // Preload
  //-----------------------------------------------
  preload() {
    //Progress Bar
    this.loadBar = this.add.graphics(0, 0);
    this.loadBar.fillStyle(0xcb0674, 1);
    this.loadBar.fillRect(this.targetX, 0, this.bar.width, this.bar.height);
    
    //Pack file - e.g. https://github.com/nzdenek/phaser-pack-loader
    this.load.pack('asset-pack', '_packs/assets.json');
  }


  //-----------------------------------------------
  // Create (happens once the manifest has been loaded)
  //-----------------------------------------------
  create() {

    //Sounds
    Sounds.init(this.sound, this.game.cache);

    //First Scene
    this.flags.ready = true;
  }


  //-----------------------------------------------
  // Create
  //-----------------------------------------------
  update() {
    //Progress
    if(this.load.totalToLoad > 0) {
      //Loaded
      if(this.loadBar.x === this.bar.width) {
        //In place
        if(this.flags.ready) {
          this.scene.start('Game');
        }
      } else {
        //Move it
        this.targetX = this.load.progress * this.bar.width;
        let diffX = (this.targetX - this.loadBar.x) / this.bar.lag;
        if(diffX < 1) {
          this.loadBar.x = this.targetX;
        } else {
          this.loadBar.x += diffX;
        }
      }
    }
    
  }
}