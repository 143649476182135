/*
*   @site           Lizzo: Ring Game
*   @function       Object: Score Panel
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Imports
//-----------------------------------------------

//Objects
//import Puck from '@/objects/game/puck';

//Utilities
import Helper from '@/utilities/helper';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class ScorePanel {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor(scene) {
    //Add
    this.scene = scene;

    //Pubsubs
    this.addPubSubs();

    //Shoot area
    this.background = this.scene.add.sprite(1080 - 30, 50, 'game-assets', 'score-back.png').setOrigin(1, 0);
    this.background.depth = 100;

    //Text
    this.text = {};
    this.addText('time-label', 134, 50, 'TIME', 40, 0x000000);
    this.addText('score-label', 340, 50, 'SCORE', 40, 0x000000);
    this.addText('time-value', 134, 90, '30', 80, 0xd77aae, true);
    this.addText('score-value', 340, 90, '0', 80, 0xd77aae, true);

    //Set score and timer to defaults
    this.gameStart();
  }


  //-----------------------------------------------
  // Add Text
  //-----------------------------------------------
  addText(id, x, y, value, size, colour, dynamic = false) {
    //Adjust X, Y
    x += this.background.x - this.background.width;
    y += this.background.y;

    //Text Items
    let shadow, text;
    if(dynamic) {
      shadow = this.scene.add.dynamicBitmapText(x, y, 'HelveticaNeueBold', value, size).setTint(0xFFFFFF);
      text = this.scene.add.dynamicBitmapText(shadow.x, shadow.y - 2, 'HelveticaNeueBold', value, size).setTint(colour);
    } else {
      shadow = this.scene.add.bitmapText(x, y, 'HelveticaNeueBold', value, size).setTint(0xFFFFFF);
      text = this.scene.add.bitmapText(shadow.x, shadow.y - 2, 'HelveticaNeueBold', value, size).setTint(colour);
    }

    //Center
    shadow.x -= shadow.width / 2;
    text.x -= text.width / 2;

    //Depth
    shadow.depth = text.depth = 100;

    //Register
    this.text[id] = {
      text:text,
      shadow:shadow,
      x:x,
      y:y
    };

  }


  //-----------------------------------------------
  // Event listeners
  //-----------------------------------------------
  addPubSubs() {
    //State / Game events
    PubSub.subscribe('game', (id, data) => { this.gameEvent(data); });
  }


  //-----------------------------------------------
  // Game Events
  //-----------------------------------------------
  gameEvent(data) {
    if(data.method === 'start') {
      this.gameStart();
    } else if(data.method === 'end') {
      this.gameEnd();
    } else if(data.method === 'score') {
      //Score!
      this.config.score += data.value;
      this.setScore(this.config.score);
    }
  }


  //-----------------------------------------------
  // Game End
  //-----------------------------------------------
  gameEnd() {}


  //-----------------------------------------------
  // Game Start
  //-----------------------------------------------
  gameStart() {
    //Values
    this.config = {score:0, timeElapased:0};

    //Text
    this.setScore(this.config.score);
    this.setTime(Helper.constants.GAME_CONFIG.time - this.config.timeElapased);
  }


  //-----------------------------------------------
  // Set the score
  //-----------------------------------------------
  setScore(value) {
    value = value + '';
    this.text['score-value'].shadow.text = value;
    this.text['score-value'].text.text = value;
    this.text['score-value'].shadow.x = this.text['score-value'].x - this.text['score-value'].shadow.width / 2;
    this.text['score-value'].text.x = this.text['score-value'].x - this.text['score-value'].text.width / 2;
  }


  //-----------------------------------------------
  // Set the time
  //-----------------------------------------------
  setTime(value) {
    value = value + '';
    this.text['time-value'].shadow.text = value.toString();
    this.text['time-value'].text.text = value.toString();
    this.text['time-value'].shadow.x = this.text['time-value'].x - this.text['time-value'].shadow.width / 2;
    this.text['time-value'].text.x = this.text['time-value'].x - this.text['time-value'].text.width / 2;
  }


  //-----------------------------------------------
  // Update
  //-----------------------------------------------
  update(state) {
    if(state === 'game-on') {
      //Start time
      if(this.config.timeStart === undefined) {
        this.config.timeStart = this.scene.game.getTime();
      }

      //Elapsed time
      this.config.timeElapsed = Math.min(Helper.constants.GAME_CONFIG.time, (this.scene.game.getTime() - this.config.timeStart) / 1000);
      let value = Helper.constants.GAME_CONFIG.time - Math.floor(this.config.timeElapsed);
      if(this.text['time-value'].text.text !== value.toString()) {
        //Time Value
        this.setTime(value);

        //Game Over event
        if(value === 0) {
          //Save the score somewhere useful
          Helper.gameInfo.score = this.config.score;
          if(Helper.cookie.user.score === '' || parseInt(Helper.cookie.user.score) < this.config.score) {
            Helper.cookie.saveUser({score:this.config.score});
          }

          //Change the state
          window.state.setState('game-over');
        }
      }
    }
  }
}
