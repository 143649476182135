/*
*   @site           Lizzo: Ring Game
*   @function       Utility Object: Sound Manager
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/

//-----------------------------------------------
// Imports
//-----------------------------------------------

//Utilities
import Helper from '@/utilities/helper';

//-----------------------------------------------
// Default Class
//-----------------------------------------------
class _Sounds {

  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor() {
    //Cache
    this.cache = {};

    //Last music track for resuming
    this.musicLast = '';
  }

  //-----------------------------------------------
  // Init
  //-----------------------------------------------
  init(sound, cache) {
    //Sound and cache references
    this.sound = sound;
    this.cache = cache;

    //Pre-cache all of the sounds
    for(let i in cache.audio.entries.entries) {
      this.cacheItem(i, {volume:1});
    }

    //Click manager on the SOUND button
    this.htmlSwitch = $('#button-sfx');
    this.htmlSwitch.on('click', () => {
      this.toggleMute();
    });
    this.setHTMLSwitch();
  }


  //-----------------------------------------------
  // Toggle Mute
  //-----------------------------------------------
  toggleMute() {
    //Turn on/off
    let newValue = (this.sound.mute) ? 1 : 0;

    //Save the value to the cookie and the game object
    Helper.cookie.audioToggle(newValue.toString());

    //Handle the class
    this.setHTMLSwitch((newValue === 1) ? 0 : 50);
  }


  //-----------------------------------------------
  // Set the HTML switch visual correctly and mute accordingly
  //-----------------------------------------------
  setHTMLSwitch(delay = 0) {
    //Get the value (reverse of our stored value if that makes sense?)
    setTimeout(() => {
      this.sound.mute = (Helper.cookie.sound === '1') ? false : true;
    }, delay);

    //Toggle class
    if(Helper.cookie.sound === '0') {
      this.htmlSwitch.find('i').removeClass('fa-volume-up').addClass('fa-volume-mute');
    } else {
      this.htmlSwitch.find('i').removeClass('fa-volume-mute').addClass('fa-volume-up');
    }
  }

  muteSet(value) {
    this.sound.mute = value;
  }


  //-----------------------------------------------
  // Cache item
  //-----------------------------------------------
  cacheItem(id, config) {
    if(this.cache[id] === undefined) {
      this.cache[id] = this.sound.add(id, config);
    }
  }


  //-----------------------------------------------
  // Set the game
  //-----------------------------------------------
  music(id, config = {}, force = false) {
    if(this.musicLast !== id || force) {
      //Start new
      if(this.play(id, config)) {
        //Kill old
        if(this.musicLast !== '' && this.musicLast !== id) {
          this.cache[this.musicLast].stop();
        }

        //Store new
        this.musicLast = id;
      }
    }
  }


  //-----------------------------------------------
  // Music Stop
  //-----------------------------------------------
  musicStop() {
    if(this.musicLast !== '') {
      this.cache[this.musicLast].stop();
      this.musicLast = '';
    }
  }


  //-----------------------------------------------
  // Play a sound
  //-----------------------------------------------
  play(id, config = {}) {
    if(this.cache.audio.exists(id)) {
      //Check our internal cache to re-use sounds
      this.cacheItem(id, config);
      
      //Play the sound from the cache
      this.cache[id].play(config);

      //Return
      return true;
    } else {
      console.warn('Missing sound entry', id);
      return false;
    }
  }

  //-----------------------------------------------
  // Stop a sound
  //-----------------------------------------------
  stop(id) {
    if(this.cache.audio.exists(id)) {
      this.cache[id].stop();
    }
  }


  //-----------------------------------------------
  // Update loop
  //-----------------------------------------------
  update(keys) {
    if(keys.just.indexOf('soundToggle') !== -1) {
      this.toggleMute();
    }
  }
}

//Export as singleton
const Sounds = new _Sounds();
export default Sounds;