/*
*   @site           Lizzo: Ring Game
*   @function       Utility Object: CRM
*   @author         Greg Findon
*   @copyright      Copyright 2019, Last17.com
*   @version        0.01
*
*********************************************************************************************/


//-----------------------------------------------
// Import
//-----------------------------------------------
import Helper from '@/utilities/helper';


//-----------------------------------------------
// Default Class
//-----------------------------------------------
export default class CRM {
  //-----------------------------------------------
  // Constructor
  //-----------------------------------------------
  constructor() { 
    //Response callback
    window.crmResponseCatcher = function(data) {
      this.crmResponse(data);
    }.bind(this);
  }


  //-----------------------------------------------
  // Register
  //-----------------------------------------------
  register(name, email, postcode, country = 'US', optin = true) {
    //Data
    let data =  {
      email:email,
      firstname:name,
      postcode:postcode,
      country:country,
      game_completion:true, //required?
      global_optin:optin //true/false from checkbox
    };

    //Send it
    this.crmSend(data, 'subscribe');
  }


  //-----------------------------------------------
  // Unsubscribe
  //-----------------------------------------------
  unsubscribe(email) {
    this.crmSend({email:email}, 'unsubscribe', 'unsubscribe');
  }


  //-----------------------------------------------
  // CRM Send
  //-----------------------------------------------
  crmSend(data, id, endpointID = 'subscribe') {
    if(Helper.constants.CRM.endpoints[endpointID]) {
      //Constants
      let dataSend = {
        newsletterID:Helper.constants.CRM.newsletterID,
        _ext: Helper.constants.CRM.ext,
        datasource:Helper.constants.CRM.dataSource,
        ref_url:document.location.origin,
        jsonp:'crmResponseCatcher',
      };

      //GEO IP Population?
      //geoip=true
      //ip_address={user’s ip}

      //Merge new data in
      for(let i in data) {
        dataSend[i] = data[i];
      }

      //Store the callback ID
      this.callbackID = id;
      this.sentData = dataSend;

      //Send
      $.ajax({
        type: 'POST',
        url: Helper.constants.CRM.endpoints[endpointID],
        data: dataSend,
        dataType: 'jsonp'
      });
    }
  }


  //-----------------------------------------------
  // CRM Response
  //-----------------------------------------------
  crmResponse(data) {
    PubSub.publish('crm', {id:this.callbackID, response:data, sent:this.sentData});
  }
}